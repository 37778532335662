import React, { Suspense, lazy, useEffect, useState } from "react";
import Home from "../components/LandingPage/Home";
import Services from "../components/LandingPage/Services";
import { useLocation } from "react-router-dom";

const OurClients = lazy(() => import("../components/AbouUs/OurClients"));
const Steps = lazy(() => import("../components/LandingPage/Steps"));

export default function LandingPage() {
    const { pathname } = useLocation();
    const [showBigComponent, setShowBigComponent] = useState(false);

    useEffect(() => {
        const checkScroll = (hasScrolled) => {
            if (hasScrolled) {
                window.removeEventListener("scroll", checkScroll);
                setShowBigComponent(true);
                return;
            }

            const scrollY = window.scrollY;
            if (scrollY > window.innerHeight) setShowBigComponent(true);
            else window.addEventListener("scroll", checkScroll);
        };

        checkScroll();

        return () => {
            window.removeEventListener("scroll", checkScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <Home />
            <Services />
            <div className="testimonial-steps">
                <Suspense fallback={<div className="lazyLoading"></div>}>
                    {showBigComponent && (
                        <>
                            <OurClients />
                            <Steps />
                        </>
                    )}
                </Suspense>
            </div>
        </div>
    );
}
