import React, { useEffect, useRef, useState } from "react";
import Whatsapp from "../assets/ContactUs/whatsapp.png";
import { FaHome } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

const WhatsApp = () => {
    return (
        <div
            className="whatsapp"
        >
            <a
                href="https://wa.me/+96178938000"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link"
            >
                <img src={Whatsapp} alt="whatsapp" />
            </a>
            <a
                href="tel:+96178938000"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link"
            >
                <img
                    src="https://img.icons8.com/ios-filled/50/ffffff/phone.png"
                    alt="phone"
                />
            </a>
        </div>
    );
};

function ContactUs() {
    const ref = useRef(null);
    const [showWhatsapp, setShowWhatsapp] = useState(false);

    const height = window.innerHeight;

    useEffect(() => {
        window.scrollTo(0, 0);
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowWhatsapp(true);
                    observer.unobserve(currentRef);
                }
            },
            {
                root: null,
                rootMargin: "0px 0px 100px 0px",
                threshold: 0.1,
            }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div>
            {showWhatsapp && <WhatsApp />}
            <div className="contact-us-screen" style={{ height: height }}>
                <p
                    style={{
                        color: "white",
                        fontSize: "5em",
                        textAlign: "center",
                    }}
                >
                    Contact Us
                </p>
                <p>
                    Discuss your security objectives with our experts. <br />
                    We're here to assist and guide you through your security
                    concerns
                </p>
            </div>
            <div className="contact-us-info" id="contact-us-navigation" ref={ref}>
                <div className="contact-us-info-details">
                    <div className="contact-us-item">
                        <FaHome size={35} color="#ffd100" />
                        <p
                            style={{ fontSize: "2em", fontWeight: "bold" }}
                        >
                            Visit us:
                        </p>
                        <p>
                            Starco Building, 4th Floor, Bloc C, office 411/415
                        </p>
                        <span>Beirut – Lebanon</span>
                    </div>
                    <div className="contact-us-item">
                        <BsTelephoneFill size={35} color="#ffd100" />
                        <p style={{ fontSize: "2em", fontWeight: "bold" }}>
                            Call us:
                        </p>
                        <p>
                            +961 1 358909 <br /> +961 1 364909
                        </p>
                        <span>Mobile & WhatsApp: +96178938000</span>
                    </div>
                    <div className="contact-us-item">
                        <HiOutlineMail size={35} color="#ffd100" />
                        <p style={{ fontSize: "2em", fontWeight: "bold" }}>
                            Contact us:
                        </p>
                        <p>
                            We are available 24/7 
                        </p>
                        <span>Info@forearmssecurity.com</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
