import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/config";

export default function BurgerMenu({ showBurgerMenu, setShowBurgerMenu }) {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
    setShowBurgerMenu(false);
  };

  return (
    <div className={showBurgerMenu ? "burger-menu" : "hidden-burger-menu"}>
      <ul style={{ paddingTop: "20%" }}>
        <li onClick={() => handleNavigation(routes.home)} style={{ paddingTop: 30 }}>Home</li>
        <li onClick={() => handleNavigation(routes.services)}>Services</li>
        <li onClick={() => handleNavigation(routes.aboutUs)}>About Us</li>
        <li onClick={() => handleNavigation(routes.contactUs)}>Contact Us</li>
      </ul>
    </div>
  );
}
