import React from "react";
import { FaDiscord } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils/config";

export default function Footer() {
    const navigate = useNavigate();

    return (
        <div className="footer">
            <div>
                © Copyright 2022 Forearms. All rights reserved.
            </div>
            <ul>
                <li onClick={() => navigate(routes.home)}>Home</li>
                <li onClick={() => navigate(routes.services)}>Services</li>
                <li onClick={() => navigate(routes.aboutUs)}>About Us</li>
                <li onClick={() => navigate(routes.contactUs)}>Contact us </li>
            </ul>
            <ul>
                <li>
                    <FaDiscord color="#ffd100" size={25} />
                </li>
                <li>
                    <BsLinkedin color="#ffd100" size={20} />
                </li>
                <li>
                    <RiInstagramFill color="#ffd100" size={25} />
                </li>
                <li>
                    <AiFillYoutube color="#ffd100" size={25} />
                </li>
            </ul>
        </div>
    );
}
