import React, { useEffect, useRef, useState } from "react";
import guards from "../../assets/AboutUs/two-guys.jpg";
import control from "../../assets/LandingPage/services/Crowd-Management-Tips-for-Crowd-Control.jpg";
import close from "../../assets/LandingPage/services/Executive-Protection-Insurance-coverage.jpg";
import monitoring from "../../assets/LandingPage/services/monitoring.jpg";
import assessment from "../../assets/LandingPage/services/ThreatVulnerabilityRisk.jpg";
import training from "../../assets/LandingPage/services/army.mil-107021-2011-04-29-130434.jpg";
import { routes } from "../../utils/config";
import { HashLink } from "react-router-hash-link";

export const services = [
    {
        title: "Static security Guards",
        longDescription:
            "Forearms offers to provide uniformed armed and unarmed front line security personnel that are tailored to meet and accommodate to the clients evolving needs. It can vary from the most simple to the most sophisticated solutions. All our Security Personnel are selected from a wide range of professional backgrounds that are well trained prior to any assignment or deployment and our service can range from residential, commercial to industrial sectors.",
        shortDescription:
            "Adaptive security personnel for evolving needs, from simple to sophisticated solutions. Our trained professionals are selected from diverse backgrounds, ensuring top-notch service for residential, commercial, and industrial sectors.",
        image: guards,

        id: 1,
    },
    {
        title: "Security crowd control",
        longDescription:
            "Forearms offers to provide and manage small and large events including but not limited to, organize access and egress, facilitate security arrangements, car management, crowd crush, affray, health and safety of people, and to prevent the outbreak of potential problems or hazards through well experienced and trained security personnel, security supervisors, patrols and line managers while using security equipment’s where and when needed (Such Walk through metal detectors / Hand held metal detectors, etc.) wile providing an exit and emergency plan in case of any crisis or disaster during the event. ",
        shortDescription:
            "Event management experts ensuring safety, access control, and crisis response. Our experienced and trained personnel, supervisors, and patrols use necessary security equipment to prevent problems and handle emergencies.",
        image: control,
        id: 2,
    },
    {
        title: "Executive close protection officers",
        longDescription:
            "Forearms offers to provide armed and unarmed Close protection officers all over the Lebanese territories. We carry out this service through a well and highly trained armed and un-armed security crews and team leaders that are available 24/7. We use security techniques, physical protection and risk mitigation measures to ensure the safety of VIPs or other individuals who may be exposed to any kind of risk. Our Elite executive protection professionals have specialized training in security executive protection, defensive tactics, driving skills, first aid, and marksmanship.",
        shortDescription:
            "Our trained armed and unarmed officers provide 24/7 close protection services. With security techniques, physical protection, and specialized skills, we ensure safety for VIPs and at-risk individuals. Trust our Elite team for comprehensive security.",
        image: close,
        id: 3,
    },
    {
        title: "Alarm Monitoring with immediate security response",
        longDescription:
            "Forearms offers to provide through its technical and operational experienced team a 24/7 central monitoring services through direct communication between your security system panel and the central station of our control Room. Since our main aim and goal is to protect and secure the life, health, asset and property of the client, we provide as well, an immediate and direct intervention service performed by our qualified and well trained emergency response team along with the nearest patrol if needed. Our alarm monitoring system includes but not limited to, intrusion detection system, Panic button and CCTV monitoring that is connected to our control panel to register any unexpected, risky and emergency event. ",
        shortDescription:
            "Forearms offers to provide through its technical and operational experienced team a 24/7 central monitoring services through direct communication between your security system panel and the central station of our control Room.",
        image: monitoring,
        id: 4,
    },
    {
        title: "Risk Assessment and Security Solutions",
        longDescription:
            "Forearms offers to provide a full and detailed security solution including security Gap, site survey and threat analysis to identify assets, detect threats, analyze vulnerabilities, and create security countermeasures or safeguards in order to protect against potential damages and prevent losses on life or assets. The risk assessment service is essential for individuals and corporate in order to asses any threat or risk prior to conducting any activity, such as,  arrangement of visits, trip, new establishment, in order to identify potential hazards and risk and being pro-active to create security steps as corrective and preventive measures. ",
        shortDescription:
            "We analyze threats, vulnerabilities, and assets to create tailored security measures. Our risk assessment service identifies hazards and offers preventive solutions for individuals and corporations. Trust us for comprehensive security.",
        image: assessment,
        id: 5,
    },
    {
        title: "Security and safety training",
        longDescription:
            "Forearms offers to provide a security and safety training courses with its technical and experienced trainers. We provide firearms Basic field Courses and implementation, close protection along with tactical driving courses for individuals and groups. The training includes theoretical and practical live shoot training managed and supervised by a well experienced instructor. Forearms with its credentials and license provides a certificate for all personnel at the end of the training courses.  ",
        shortDescription:
            "We offer firearms, close protection, and tactical driving courses. Led by experienced trainers, our training includes theoretical and practical exercises. Participants receive certificates upon completion. Trust us for expert training.",
        image: training,
        id: 6,
    },
];

export default function Services() {
    const targetRef = useRef(null);
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const currentTarget = targetRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setAnimate(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: "-150px",
                threshold: 0.000001,
            }
        );

        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    return (
        <div className="services" id="red" ref={targetRef}>
            <div className={animate ? "our-services-animation" : 'our-services'} >
                <div
                    style={{
                        marginBottom: 80,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 20,
                    }}
                >
                    <h1 style={{ fontSize: 40, fontWeight: "bold" }}>
                        Your Trusted Security Solution
                    </h1>
                    <p className="intro">
                        Welcome to Forearms Security, where your safety and
                        protection are our top priority. As we are setting a new
                        benchmark in the security industry, our aim is to meet
                        and accommodate all your safety needs, fostering a safer
                        and brighter future for our country. Forearms Security
                        was established in 2018, which provides and facilitates
                        full security solutions across Lebanon. At Forearms
                        Security, we aim to be your shield against threats and
                        risks, providing a diverse portfolio of cutting-edge
                        security solutions tailored to your specific needs. Our
                        expert team of highly trained and professionals
                        background, offers you a wide array of services to
                        fortify your safety, protect your assets, and uphold
                        your peace of mind.
                    </p>
                </div>
                <div className="services-container">
                    {services.map((service, index) => (
                        <HashLink
                            smooth
                            to={`${routes.services + "#" + service.id}`}
                            key={index}
                        >
                            <img src={service.image} alt={service.title} />
                            <h2>{service.title}</h2>
                            <p>{service.shortDescription}</p>
                        </HashLink>
                    ))}
                </div>
            </div>
        </div>
    );
}
