import React, {useRef, useState, useEffect} from "react";

function OurMission() {
    const targetRef = useRef(null);
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const currentTarget = targetRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setAnimate(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.000001,
            }
        );

        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    return (
        <div className="our-mission" ref={targetRef}>
            <p
                style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "3.5em",
                }}
                className={animate ? "our-services-animation" : ''} 
            >
                Our Values
            </p>

            <ul className="responsive-list">
                <li className={animate ? "our-services-animation" : ''} >
                    We deeply value discipline, courage, perceptiveness, and the
                    commitment to going the extra mile to ensure your safety at
                    all times. Our service is rooted in transparency, honesty,
                    and integrity.
                </li>
                <li className={animate ? "our-services-animation" : ''} >
                    We prioritize safety first, emphasizing our commitment
                    through diversity, inclusion, adaptable responses, and
                    comprehensive security solutions.
                </li>
                <li className={animate ? "our-services-animation" : ''} >
                    We lead by example, honoring our commitments to protect and
                    safeguard sensitive information while upholding the utmost
                    levels of confidentiality, no matter the cost.
                </li>
                <li className={animate ? "our-services-animation" : ''} >
                    We have a client-centric focus. Relentlessly pursuing
                    excellence, we tailor our solutions to meet each client's
                    unique needs and strive to exceed their expectations.
                </li>
            </ul>
        </div>
    );
}

export default OurMission;
