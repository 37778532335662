import React, { useEffect } from "react";
import { services } from "../components/LandingPage/Services";

function Service() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="service-container">
            {services.map((service, index) => (
                <div key={index} className="service" id={`${service.id}`}>
                    <img src={service.image} alt={service.title} />
                    <h1>{service.title}</h1>
                    <p>{service.longDescription}</p>
                </div>
            ))}
        </div>
    );
}

export default Service;
