import React, {useRef, useState, useEffect} from "react";

function OurVision() {
    const targetRef = useRef(null);
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const currentTarget = targetRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setAnimate(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.000001,
            }
        );

        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    return (
        <div className="our-vision" ref={targetRef}>
            <p
                style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "3.5em",
                }}
                className={animate ? "our-services-animation" : ''} 
            >
                Our Vision
            </p>
            <h3 className={animate ? "our-services-animation" : ''} >
                Located in the dynamic heart of Beirut's downtown, Forearms
                Security stands as a leading security force in Lebanon. Our
                vision is to set industry benchmarks. We aim for a safer
                tomorrow by reshaping security paradigms, utilizing advanced
                technologies, and understanding the ever-evolving needs of the
                country and our clients for a more secure future.
            </h3>
        </div>
    );
}

export default OurVision;
