// Utilities
import "./App.css";
import { useState } from "react";
import { routes } from "./utils/config";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import AboutUs from "./pages/AboutUs";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import LandingPage from "./pages/LandingPage";
import ContactUs from "./pages/ContactUs";
import Service from "./pages/Service";

function App() {
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance + 50;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe || isRightSwipe)
            isLeftSwipe ? setShowBurgerMenu(true) : setShowBurgerMenu(false);
    };

    return (
        <BrowserRouter>
            <div
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                className="App"
            >
                <Navbar
                    showBurgerMenu={showBurgerMenu}
                    setShowBurgerMenu={setShowBurgerMenu}
                />
                <Routes>
                    <Route path={routes.home} element={<LandingPage />} />
                    <Route path={routes.services} element={<Service />} />
                    <Route path={routes.aboutUs} element={<AboutUs />} />
                    <Route path={routes.contactUs} element={<ContactUs />} />
                    <Route path="*" element={<Navigate to={routes.home} />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
