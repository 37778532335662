import React from "react";
import { HashLink } from "react-router-hash-link";
import { routes } from "../utils/config";
import { services } from '../components/LandingPage/Services'

export default function ServicesMenu({ showMenu, background }) {
  return (
    <ul
      style={{
        display: showMenu ? "flex" : "none",
        position: "absolute",
        top: background ? 20 : 30,
        backgroundColor: "#ffd100",
        width: background ? 200 : 220,
        flexDirection: "column",
        borderRadius: 10,
        fontSize: background ? "1.2em" : "1em",
      }}
      className="navbar-services"
    >
      {services.map((service, index) => (
        <HashLink key={index} className="hashLink" smooth to={`${routes.services}#${service.id}`}>
          <li>{service.title}</li>
        </HashLink>
      ))}
    </ul>
  );
}
