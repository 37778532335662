import React, { useEffect, useRef, useState } from "react";
import back from '../../assets/AboutUs/back.jpg'

export default function OurStory() {
    const targetRef = useRef(null);
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const currentTarget = targetRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setAnimate(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.000001,
            }
        );

        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    return (
        <div className="our-story">
            <img src={back} alt="security"/>
            <p ref={targetRef}>
                Our Story
            </p>
            <h3
                style={{
                    textAlign: "center",
                    width: "90%",
                    margin: "auto",
                    marginTop: 70,
                    color: "white",
                    lineHeight: "1.5em",
                }}
                className={animate ? "our-services-animation" : ''}
            >
                Forearms is a Security Company in Lebanon, established in 2018. We provide comprehensive security solutions, including safety proactive measures, personnel protection, premises safeguarding, crowd control, alarm monitoring with an emergency response team, integrated security systems, and advanced security training across all Lebanese territories. Our dedicated Control Room Center is supervised and monitored by experienced, well-trained operators, ensuring both clients and security personnel receive assistance and instructions promptly in the event of a security breach or risk detection. 
            </h3>
        </div>
    );
}
