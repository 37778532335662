import React, {useRef, useState, useEffect} from "react";

function OurAim() {
    const targetRef = useRef(null);
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const currentTarget = targetRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setAnimate(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.000001,
            }
        );

        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    return (
        <div className="our-vision" ref={targetRef}>
            <p
                style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "3.5em",
                }}
                className={animate ? "our-services-animation" : ''}
            >
                Our Aim
            </p>
            <h3    className={animate ? "our-services-animation" : ''}>
                Our aim is to offer our clients a contemporary security
                strategy that aligns with the country's evolving security
                landscape. We achieve this through detailed risk assessment case
                studies, a disciplined staff adhering to strict codes of
                conduct, and our own set of stringent policies, procedures, and
                quality management systems. We ensure prompt responses to
                emergencies, guided by our SOPs and SOW. Our trained personnel
                are not only equipped to meet our clients' demands but also
                become an integral part of their image, identity, security,
                safety, and culture.
            </h3>
        </div>
    );
}

export default OurAim;
