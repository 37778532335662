import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import User from "../assets/navbar/User.svg";
import Phone from "../assets/navbar/Phone.svg";
import { BiMenu } from "react-icons/bi";
import BurgerMenu from "./LandingPage/Mobile/BurgerMenu";
import ServicesMenu from "./ServicesMenu";
import { MdSecurity } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../utils/config";

const logo = require("../assets/navbar/logo.png");

export default function Navbar({ showBurgerMenu, setShowBurgerMenu }) {
  const [background, setBackground] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // // Active page styling
  // let activeStyle = { color: "white", textDecoration: "none" };
  // let activeClass = "activated";
  const navigation = useNavigate();

  useEffect(() => {
    // Change navbar background on scroll
    const changeBackground = () => {
      window.scrollY >= 60 ? setBackground(true) : setBackground(false);
    };
    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    <div className={background ? "nav background" : "nav"}>
      {/* === Logo & Title */}
      <div
        className={background ? "logo-div logo-div-short" : "logo-div"}
        onClick={() => navigation(routes.home)}
        style={{ cursor: "pointer" }}
      >
        <img src={logo} alt="logo" />
        <p className="logo-title">
          <span> FOREARMS</span> security
        </p>
      </div>

      {/* === Top right menu === */}
      <ul className="nav-ul">
        <li>
          <NavLink
            to={routes.home}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
            Home
          </NavLink>
        </li>
        <li
          onClick={() => {
            navigation(routes.services);
          }}
          onMouseEnter={() => {
            setShowMenu(true);
          }}
          onMouseLeave={() => {
            setShowMenu(false);
          }}
        >
          {/* <img src={Face} alt="logo" className="nav-img" /> */}
          {/* <BiMenu /> */}
          <MdSecurity />
          <div
            style={{
              position: "relative",
              display: "flex",
              gap: background ? 6 : 8,
              alignItems: "center",
              cursor: 'pointer'
            }}
            className={showMenu && "services-item-details"}
          >
            Services
            <ServicesMenu showMenu={showMenu} background={background} />
            <span className="services-item-details-icon">&#9650;</span>
          </div>
        </li>
        <li>
          <img src={User} alt="logo" className="nav-img" />

          <NavLink
            to={routes.aboutUs}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
            About Us
          </NavLink>
        </li>
        <li>
          <img src={Phone} alt="logo" className="nav-img" />
          <NavLink
            to={routes.contactUs}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
            Contact Us
          </NavLink>
        </li>
      </ul>

      {/* --- Mobile Burger Menu --- */}
      <BiMenu
        className="burger-icon-open"
        size={27}
        color="#ffd100"
        onClick={() => setShowBurgerMenu(true)}
      />
      <BurgerMenu
        setShowBurgerMenu={setShowBurgerMenu}
        showBurgerMenu={showBurgerMenu}
      />
    </div>
  );
}
