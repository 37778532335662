import React from "react";

function OurValues() {
    return (
        <div className="our-value">
            <p
                style={{
                    fontSize: "3.5em",
                }}
            >
                Our Mission
            </p>

            <h3>
                At Forearms Security, our mission is to fortify Lebanon's future
                by consistently protecting our clients, their assets, and
                valuables. With our dedicated team, advanced expertise, and
                bespoke security solutions, we aim to create an environment
                where businesses, communities, and individuals can thrive
                without concern. We're always ready to defuse any situations
                that might pose a threat or risk.
            </h3>
        </div>
    );
}

export default OurValues;
