import React from "react";
import { routes } from "../../utils/config";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

export default function Home() {
    const height = window.innerHeight;

    return (
        <div className="landingPage" style={{ height: height }}>
            <strong>
                We work to close your <p> security gaps</p>
            </strong>
            <HashLink
                smooth
                to={routes.contactUs + "#contact-us-navigation"}
                className="contact-us-btn"
            >
                Contact Us
            </HashLink>
        </div>
    );
}
