import React, { useEffect } from "react";
import OurStory from "../components/AbouUs/OurStory";
import { useLocation } from "react-router-dom";
import OurVision from "../components/AbouUs/OurVision";
import OurMission from "../components/AbouUs/OurMission";
import OurAim from "../components/AbouUs/OurAim";
import OurValues from "../components/AbouUs/OurValues";


export default function AboutUs() {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div
            style={{
                backgroundImage:
                    "linear-gradient(to bottom right, rgb(91, 91, 91), black)",
            }}
        >
            <OurStory />
            <OurAim />
            <OurVision />
            <OurMission />
            <OurValues />
        </div>
    );
}
